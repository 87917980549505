import React, { useState, useEffect } from "react";
import "./App.css";
import NameApp from "./NameApp";

const calculateTargetTime = () => {
  const now = new Date();
  const targetDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1,
    20,
    0,
    0
  ); // Tomorrow at 8:00 PM
  return targetDate.getTime();
};

const App = () => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [name, setName] = useState("");
  const [timerActive, setTimerActive] = useState(false);
  const [gameActive, setGameActive] = useState(false);

  // useEffect(() => {
  //   const targetTime = calculateTargetTime();

  //   const updateTimer = () => {
  //     const now = new Date().getTime();
  //     const distance = targetTime - now;

  //     if (distance <= 0) {
  //       setTimerActive(false);
  //       setTimeLeft(0);
  //       alert("The countdown has finished! You can now access the game.");
  //     } else {
  //       setTimeLeft(distance);
  //     }
  //   };

  //   updateTimer();
  //   const intervalId = setInterval(updateTimer, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);


  useEffect(() => {
    startLog();
  }, []);

  const startLog = () => {
    try {
      // Send a POST request to the PHP API
      fetch("https://sumathieswaran.co.uk/validatename.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "Launch",
          string: "",
        } as any),
      })
        .then((response) => {
        })
        .catch((err) => {
        });

      // Check if the response is okay
    } catch (error) {
    } finally {
    }
  };

  const formatTimeLeft = (time: any) => {
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const submitName = () => {
    if (name && name.length > 4) {
      setGameActive(true);
    }
  };

  return (
    <>
      {timerActive && (
        <>
          <div className="game-container">
            <img src="/welcome.jpg"></img>
            <div className="countdown">
              <h1>{formatTimeLeft(timeLeft)}</h1>
            </div>
          </div>
        </>
      )}

      {!timerActive && !gameActive && (
        <>
          <div className="game-container">
            <img src="/welcome.jpg"></img>
            <h3>Enter Your Name:</h3>
            <div>
              <label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </label>
            </div>
            <div>
              <button className="" onClick={() => submitName()}>
                Submit
              </button>
            </div>
          </div>
        </>
      )}
      {gameActive && <NameApp userName={name}></NameApp>}
    </>
  );
};

export default App;
