import React, { useState, useEffect } from "react";
import "./App.css";

const alphabet = "rpuaadyeggns";

const NameApp = ({ userName }: { userName: string }) => {

  useEffect(() => {
    startLog();
  }, []);

  const startLog = () => {
    try {
      // Send a POST request to the PHP API
      fetch("https://sumathieswaran.co.uk/validatename.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: userName + "-GameLaunch",
          string: "",
        } as any),
      })
        .then((response) => {
        })
        .catch((err) => {
        });

      // Check if the response is okay
    } catch (error) {
    } finally {
    }
  };

  const [showGame, setShowGame] = useState<boolean>(true);
  const [indexList, setIndexList] = useState<Array<number>>([]);
  const [displayName, setDisplayName] = useState([
    "P",
    "_",
    "A",
    "_",
    "_",
    "_",
    "E",
    "_",
    "_",
    "_",
    "_",
    "_",
    "_",
    "N",
  ]);
  const [availableLetters, setAvailableLetters] = useState(
    alphabet.toUpperCase().split("")
  );

  const handleButtonClick = (letter: any) => {
    const letters: Array<string> = JSON.parse(JSON.stringify(displayName));
    const ind = letters.findIndex((n) => n === "_");
    if (ind > -1) {
      letters[ind] = letter;
      setDisplayName(letters);
      indexList.push(ind);
      setIndexList(indexList);
      const availableLetters2: Array<string> = JSON.parse(
        JSON.stringify(availableLetters)
      );
      const ind2 = availableLetters2.findIndex((n) => n === letter);
      availableLetters2.splice(ind2, 1);
      setAvailableLetters(availableLetters2);
    }
  };
  const backButton = () => {
    const lastIndex = indexList[indexList.length - 1];
    const indexList2: Array<number> = JSON.parse(JSON.stringify(indexList));
    indexList2.splice(indexList.length - 1, 1);
    const availableLetters2: Array<string> = JSON.parse(
      JSON.stringify(availableLetters)
    );
    setIndexList(indexList2);
    const letters: Array<string> = JSON.parse(JSON.stringify(displayName));
    availableLetters2.push(letters[lastIndex]);
    setAvailableLetters(availableLetters2);
    letters[lastIndex] = "_";
    setDisplayName(letters);
  };
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const resetGame = () => {
    setResponseMessage("");
    setShowGame(true);
    setDisplayName([
      "P",
      "_",
      "A",
      "_",
      "_",
      "_",
      "E",
      "_",
      "_",
      "_",
      "_",
      "_",
      "_",
      "N",
    ]);
    setAvailableLetters(alphabet.toUpperCase().split(""));
    setIndexList([]);
  };
  const submitButton = () => {
    setIsLoading(true);
    setResponseMessage("");
    setShowGame(false);

    try {
      // Send a POST request to the PHP API
      fetch("https://sumathieswaran.co.uk/validatename.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: userName,
          string: displayName.join(""),
        } as any),
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((result) => {
              if (result.success) {
                setResponseMessage(result.message);
              } else {
                setResponseMessage(result.message);
              }
            });
          } else {
            setResponseMessage("Failed to communicate with the server.");
          }
        })
        .catch((err) => {
          setResponseMessage("An error occurred while making the request.");
        });

      // Check if the response is okay
    } catch (error) {
      setResponseMessage("An error occurred while making the request.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {responseMessage === "Success" && (
        <div className="game-container">
          <h1>You're Correct</h1>
          <img src="/success.webp"></img>
        </div>
      )}
      {responseMessage &&
        responseMessage.length > 0 &&
        responseMessage !== "Success" && (
          <div className="game-container">
            <h1>Sorry Wrong Guess! </h1>
            <img src="/wrong.jpg"></img>
            <button  className="btn-restart" onClick={() => resetGame()}>
              Replay
            </button>
          </div>
        )}
      {showGame && (
        <>
          <div className="game-container">
            <img style={{ height: "200px" }} src="/guess.jpeg"></img>
            <h3>Guess The Name</h3>
            <div className="word-container">{displayName.join(" ")}</div>
            <div className="grid-container">
              {availableLetters.map((letter, ind) => (
                <div
                  key={letter + "_" + ind}
                  className="button"
                  onClick={() => handleButtonClick(letter)}
                >
                  {letter}
                </div>
              ))}
            </div>
            {indexList.length > 0 && (
              <button className="btn-remove" onClick={() => backButton()}>
                Remove
              </button>
            )}
            <button className="btn-restart" onClick={() => resetGame()}>Restart</button>

            {availableLetters.length === 0 && (
              <button
                className=""
                onClick={() => submitButton()}
                disabled={isLoading}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default NameApp;
